import * as React from "react"
import PropTypes from "prop-types"
import "../styles/independent.scss"

import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {
    return (
        <>
            <header>
                <meta name="robots" content="noindex" />
            </header>
            <div
                style={{
                    padding: `0 0`,
                    margin: `10px`
                }}
            >
                <main>
                    {children}
                </main>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
