import React from "react"
import PageLayout from "../components/independent-layout"

import Itemize from "../components/itemize"
import Button from 'react-bootstrap/Button'

export default function Home() {
   return (
      <PageLayout title="利用規約">
         <div class="section">
            <h3>会員規約</h3>
            <Itemize>{`第１条（名称）
                本バレエスタジオは、「YUKI MAMIYA BALLET STUDIO」を正式名称とし、必要に応じて「眞宮バレエスタジオ」と表記することがあります。（以下本スタジオとする）
                ---
                第２条（所在地）
                本スタジオは、東京都目黒区中根１丁目３−９森戸ビル２０１号室におきます。
                ---
                第３条（運営・管理）
                本スタジオは、株式会社S.MATSUNAGA（以下会社とする）が会則・細則を定めて、運営及び管理にあたります。
                ---
                第４条（入会資格・対象）
                --
                （１）満3歳（トイレトレーニングが終了している）から、小学生以下の男女及び中学生以上の女性の方
                --
                （２）医師により運動の制限及び禁止をされていない、また伝染病やその他疫病等を有していない方
                --
                （３）会員に相応しい品格と社会的信用のある方（身体に刺青やタトゥーのある方、暴力団員またはそれらの支配下にあるものの関係者、その他本スタジオが相応しくないと判断した方の入会はお断りいたします。また、ご入会後であってもこれらの事由に該当する場合は、入会取り消しとなります。）
                ---
                第５条（入会手続き）
                本スタジオに入会を希望する場合は、会員規約に同意し所定の申し込み手続きを行っていただきます。ご入会金及び初回お月謝等の納入確認後に、会員となります。（未成年者の場合、保護者の同意を得て申し込んでください。）
                ---
                第６条（入会金及びレッスン代金の納入及び返金）
                入会金及びレッスン代金等について、納入後のご返金はできません。ご家族（母子、兄弟・姉妹等）の入会金は二人目以降を半額とします。
                一度退会された場合は、再度入会手続きと入会金の納入をして頂きます。
                レッスン代金はお月謝制とし、前月の2６日に銀行口座からのお引き落とし、またはお振り込み（手数料はご負担ください）によってお支払い頂きます。現金での納入は受け付けておりません。レッスンチケットのみ、現金での購入ができます。（できるだけおつりのないようにご用意ください。）
                維持費は毎年５月分のお月謝（４月のお引き落とし）と一緒にお支払い頂きます。
                途中入会の場合、お月謝は日割り、維持費は月割り（１ヶ月1,100円）で計算し、お振り込みにて納入してください。
                一度納入された代金は、チケット購入を含めご返金できません。
                ---
                第７条（レッスン日及び回数の変更）
                レッスン日の変更を希望する場合、前月の２５日までにお申し出ください。ただし定員により、ご希望に添えない場合があることをご了承ください。レッスン回数の変更は、回数を減らす場合前月の７日までにお申し出ください。回数を増やす場合、お月謝の差額をお振込いただくことにより、随時受付いたします。
                ---
                第８条（休会）
                会員が長期間のお休みを希望する場合、前月の７日までにお申し出があれば休会扱いとし、休会費は月額2,200円とします。お申し出がない場合、もしくは8日以降にお申し出があった場合は通常通りのお月謝を納入して頂きます。
                ---
                第９条（退会）
                会員が退会を希望する場合、希望する月の７日までにお申し出ください。８日以降にお申し出があった場合は、翌月末の退会となりますので翌月分のお月謝を納入して頂きます。
                ---
                第１０条（会員資格の一時停止及び除名）
                本スタジオは、会員が以下の事項に該当すると判断した場合、会員資格の一時停止または除名するものとします。
                --
                （１）レッスン代金及びその他の代金の納入が正当な理由なく行われず、延滞等が２ヶ月以上あった時
                --
                （２）本スタジオへの届出住所、連絡先に連絡しても３ヶ月以上連絡不能になった時
                --
                （３）本スタジオの規約または法令に反し、注意を受けても応じない時
                --
                （４）本スタジオの名誉を傷つける行為、または秩序を乱す行為があった時
                --
                （５）本スタジオ及び他の会員への迷惑行為、誹謗中傷等があった時
                --
                （６）その他、本スタジオ会員として不適格と会社が判断した時
                ---
                第１１条（施設の廃止、変更）
                本スタジオは、必要に応じて施設の廃止、変更ができるものとします。
                --
                （１）緊急事態の発生、地震・洪水等の天災などにより、施設を使用できない状況になった時には、本スタジオ施設の利用制限や廃止または変更をすることがあります。
                --
                （２）いずれの事由においても、会員は一切の異議申し立て及び補償の請求をする事はできません。
                ---
                第１２条（料金の変更）
                会員が負担する入会金、レッスン代金等は諸事情により変更ができるものとします。
                その場合、変更日の２ヶ月前までに通知を行い、継続の意志がある場合はそれに応じて頂きます。
                ---
                第１３条（担当講師及びスケジュールの変更）
                本スタジオは、会員に通知の上で必要に応じて担当講師の変更及びレッスンスケジュールの変更を行うことができるものとします。
                ---
                第１４条（受講の拒否）
                インフルエンザ、COVID-19、その他感染症の症状がある方及び感染している可能性のある方（濃厚接触者に該当する方）の受講はお断りします。
                また、怪我や持病により運動に制限がある場合は、必ず受講前に講師に報告してください。受講が難しいと判断される場合には、お断りすることがあります。
                ---
                第１５条（責任事項、禁止事項）
                --
                （１）本スタジオにおいて発生した盗難、紛失、負傷、病気、会員同士及び保護者同士のトラブルなどについてはいずれも自己責任とし、会社は一切の責任を負いません。
                   いずれの事由においても会員・保護者は補償請求をすることができません。
                --
                （２）営利目的での勧誘や活動、物品の販売などは禁止します。
                --
                （３）講師の個人的な連絡先を聞く行為、無許可での撮影・録画・録音その他迷惑行為は禁止します。
                ---
                第１６条（スポーツ保険）
                ご入会にあたり、スポーツ安全協会の保険にご加入(任意)頂くことができます。
                （レッスン中は細心の注意を払って指導にあたりますが、万が一の負傷に備えてご加入頂くことをお勧めしております。）お支払いは初回レッスン日までに他のお支払いと一緒にお振込願います。年度途中からのご加入でも１年分の保険料となります。3月末日まで有効で、次年度継続する場合は4月に再加入し5月分のお月謝（4月のお引き落とし）と一緒にお支払い頂きます。
                ---
                第１７条（写真・映像の使用）
                個人を特定できる写真・映像を使用する際（SNSを含む）には、被写体となる会員及び保護者の承諾を必要とするものとします。
                会員や保護者が、講師や他の会員をスタジオ内で撮影する事はご遠慮ください。
                ---
                第１８条（個人情報の取り扱い）
                本スタジオは、収集した個人情報を以下の利用目的以外に利用致しません。
                --
                （１）会員への連絡、通知
                --
                （２）お問い合わせに対する回答
                --
                （３）会員へのサービス提供、向上
                本スタジオは会員から取得した個人情報を第三者に開示または提供することはありません。ただし、以下の事項に該当する場合を除きます。
                --
                （１）会員ご本人、または保護者の同意がある場合
                --
                （２）法令に基づく場合
                --
                （３）警察からの要請等、官公庁からの要請があった場合
                --
                （４）人の生命、身体の保護に関わることで必要と判断された場合
                ---
                第１９条（届出事項の変更等）
                会員は、氏名・住所・連絡先・支払い金融機関及びその他について、入会申し込み時の記載事項に変更があった場合は、速やかに届け出るものとし、変更の届けがなく連絡や通知の未達が発生した場合の責任は会員が負うものとします。
                ---
                第２０条（ビジター）
                会員以外の方が受講を希望される場合はビジターとして受講できますが、会則及び細則は会員と同様に遵守するものとします。
                ---
                第２１条（会則及び細則の改正）
                会則及び細則は必要に応じて改正することがあります。改正を行う場合は会員に通知するものとし、改正による効力は全ての会員に及ぶものとします。`}</Itemize>
         </div>
         <div class="section">
            <h3>レッスン細則</h3>
            <Itemize>{`--
                   （１）スタジオのお休みについて
                   第５週目のレッスンはお休みです。ただしスタジオや講師の都合により通常レッスンが行われなかった場合などには、第５週目にレッスンを行うことがあります。
                   年末年始のお休みと夏休み（年によって日程は異なる）がありますが、それによって月のレッスン回数が４回に満たない場合は第５週目にレッスンを行う、もしくは振替レッスンを受講することができます。

                   --
                   （２）欠席・振替について
                      欠席をする場合は、レッスン開始時刻の前に contact@yukimamiya-ballet.comまでメールでご連絡ください。レッスン開始後は無断欠席の扱いとなります。
                      無断欠席の場合には振替レッスンを受講できませんのでご注意ください。
                      欠席のご連絡をいただいた場合、振替レッスンを受講することができます。
                      振替の有効期限は年度末（3月末）としますが、できるだけ４週間以内を目安に振替レッスンを受講してください。
                      年度末（3月末）をもって、全ての振替レッスン権利をリセット（失効）いたしますので、ご注意ください。
                      年度末は混み合うことが予想されますので、定員によりご希望に添えないこともございます。
                      早めの振替受講をお勧めいたします。

                   --
                   （３）遅刻・早退について
                      遅刻によって怪我の可能性が高まることや、レッスン進行の妨げになることなどから本来ならば許可できませんが、学校行事・その他やむを得ない事情によって遅刻をする場合は必ずご連絡ください。
                      レッスン開始時刻の１０分後までは入室を許可します。
                      １０分以上の遅刻は、怪我防止の観点から許可できません。
                      時間に余裕をもってお越し下さい。
                      体調不良やその他の理由で早退する事は可能ですが、その分の振替受講はできません。

                   --
                   （４）休講・代講について
                      天災や悪天候、またはスタジオや講師の都合により、やむを得ず休講もしくは代講となる場合がございます。休講になった場合は、振替レッスンの対象となります。
                      事前にわかっている場合はスタジオ掲示でお知らせいたしますが、緊急の場合一斉メールでの通知とさせて頂きます。

                   --
                   （５）見学について
                      通常レッスンの見学はご遠慮ください。ただし見学日を３ヶ月に１回程度設けますので、見学ご希望の場合はお申し出頂き、指定日に見学をすることができます。
                      特別な理由があって指定日以外の見学をご希望の場合は、事前に担当講師にご相談ください。

                   --
                   （６）レッスン着について
                      子どもクラス、宝塚音楽学校受験クラスの方は、レオタード・ピンクタイツ・バレエ用ショーツ・ピンク系のバレエシューズをご用意ください。
                      レオタードの形や色に指定はありませんが、レッスン用の動きやすいものをご用意ください。
                      ジュニアCクラス以上はスカートのないものにして下さい。
                      上着類の着用は、講師の許可を得て下さい。
                      日曜日の受験専門クラスを受講する際は、黒色の丸首７分袖レオタードを着用して下さい。
                      Jazzクラスを受講する際は、動きやすい服装（Ｔシャツにダンスパンツやオーバータイツ等で良い）で、シューズは指定のベージュのジャズシューズ(カペジオ製)をご用意下さい。
                      大人クラスの方は、特に決まりはありません。
                      
                   --
                   （７）髪型について
                      子どもクラス、宝塚音楽学校受験クラスの方は、シニヨン（お団子）を基本として、前髪をあげて（ピン留めも可）下さい。
                      ショートカットの方は、できるだけ動きの妨げにならないように工夫して下さい。
                      （髪型講習会もございますので、希望者はお申し出下さい。）
                      大人クラスの方は特に指定はありませんが、動きやすいようにまとめて下さい。

                   --
                   （８）教室の掛け持ち、外部講習について
                      子どもクラス、宝塚音楽学校受験クラスの方が教室の掛け持ち、外部講習受講をされる際は講師に許可をとって下さい。無断での外部受講はお控え下さい。
                      （大人クラスは除く）

                   --
                   （９）貴重品について
                      貴重品の管理は自己責任とします。スタジオ内に持ち込みをするなど、各自で徹底して下さい。

                   --
                   （10）私物の管理について
                      子どもクラスの方の学校指定靴や、長靴、傘などの取り違え（持ち帰り）の可能性がありますので、私物に名前を書くなど各自で工夫して管理をしてください。

                   --
                   （11）飲食について
                      スタジオ内での飲食は禁止です。（水分補給のための水、お茶を除く）
                      間食等を必要とする場合は、講師の許可をとって下さい。水やお茶はスタジオ内に持ち込みができます。

                   --
                   （12）携帯電話の利用について
                      スタジオ内では携帯電話をマナーモードに設定の上、通話はお控え下さい。レッスン中はその他の機能のご利用もお控え下さい。

                   --
                   （13）録音・撮影について
                      レッスン中の録音及び写真・ビデオ撮影は、保護者の方の見学時を含め禁止です。
                      （レッスンの記録や確認の為に講師が許可した場合を除く）

                   --
                   （14）駐輪について
                      スタジオのビル１階付近は駐輪禁止です。撤去されますので、必ず近隣の駐輪場に置いて下さい。

                   --
                   （15）往復時の注意
                      小さなお子様がレオタードのままスタジオの外に出ますと、不審者や映像被害等の心配がありますので、上に羽織るものをお持ちいただくか、お着替えをお持ちください。

                   --
                   （16）領収書について
                      領収書をご入用の際には、お申し出下さい。`}</Itemize>
         </div>
         <div class="section">
            <Button variant="outline-primary" href="https://www.star7.jp/yukimamiya-ballet/touroku_forms/form001.php" size="lg">確認しました</Button>
         </div>
      </PageLayout >
   )
}