import React from "react"

const Itemize = ({ children }) => {
    let li = [];
    children.toString().split("---\n").forEach(element => {
        let p = [];
        element.split("--\n").forEach((paragraph, index) => {
            if (index === 0) {
                p.push(paragraph);
            }
            else {
                p.push(<p>{paragraph}</p>);
            }
        })
        li.push(<li>{p}</li>);
    });

    return (
        <div className="terms-of-use">
            <ul>{li}</ul>
        </div>
    )
}

export default Itemize